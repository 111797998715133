<template>
	<section class="parent-container-shop">
		<div class="navigation">
			<ul class="passive-navigation">
				<li class="passive-navigation__item">
					<router-link to="/">Home</router-link>
				</li>
				<li class="passive-navigation__item">Shop</li>
			</ul>
		</div>
		<div class="menu-categories">
			<ul class="categories-list">
				<li class="categories-list__item select" @click="setSelectCategory($event.target, null)">All products</li>
				<li class="categories-list__item" @click="setSelectCategory($event.target, category.id)" v-for="category in receiveCategory" :key="category.id">
					{{ category.name }}
				</li>
			</ul>
		</div>
		<div class="menu-filtration">
			<h3 class="categories-title">{{ selectCategory }}</h3>
			<div class="icons-container">
				<div class="icon-container search">
					<div class="icon" :class="{ 'search-active': searchActive }">
						<input class="search-by-name-product" v-model="search.inputData" placeholder="Search" type="text" />
						<img src="@/assets/img/Shop/Search.svg" alt="" @click="setSearchActive()" />
					</div>
				</div>
				<div class="icon-container category">
					<div class="icon">
						<div class="filtration-container" :class="{ 'filtration-active': filtrationActive }">
							<img class="close-arrow" src="@/assets/img/Shop/Arrow-right.svg" @click="setFiltrationActive()" alt="" />
							<h6 class="filtration-name clear" @click="clearAll()" v-if="categoryList.length !== 0 || rangeValue[0] !== 8.99 || rangeValue[1] !== 50">Clear all</h6>
							<h6 class="filtration-name" @click="setCategoryActive()">By category</h6>
							<h6 class="filtration-name" @click="setPriceActive()">By price</h6>
						</div>
						<img src="@/assets/img/Shop/Filter.svg" alt="" @click="setFiltrationActive()" />
					</div>
					<div class="price-tile" :class="{ openPrice: priceActive }">
						<div class="close">
							<img @click="setPriceActive()" src="@/assets/img/Shop/Close.svg" alt="" />
						</div>
						<div class="price-slider-container">
							<Slider v-model="rangeValue" :min="receivePrice.min" :max="receivePrice.max" v-bind="option" class="slider-purple" />
						</div>
						<div class="tile-footer">
							<h6 class="footer-name" @click="setPriceActive()">Clear</h6>
							<h6 class="footer-name result" @click="filterRequest()">Show result</h6>
						</div>
					</div>
					<div class="category-tile" :class="{ openCategory: categoryActive }">
						<div class="close">
							<img @click="categoryActive = false" src="@/assets/img/Shop/Close.svg" alt="" />
						</div>
						<div>
							<ul class="category-list">
								<li class="category-list__item" v-for="category in receiveCategory" :key="category.id">
									<h6>{{ category.name }}</h6>
									<div class="custom-checkbox-container">
										<input @change="setQuantityProductsInCategory(category.productCount, category.id)" class="custom-checkbox" type="checkbox" v-model="categoryList" :value="category.id" />
										<label class="custom-label"></label>
									</div>
								</li>
							</ul>
						</div>
						<div class="tile-footer">
							<h6 class="footer-name">Clear</h6>
							<h6 class="footer-name result" @click="filterRequest()">Show result</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="container-search-mobile">
				<div class="icon-search-active">
					<input class="search-by-name-product" v-model="search.inputData" placeholder="Search" type="text" />
					<img src="@/assets/img/Shop/Search.svg" alt="" />
				</div>
			</div>
		</div>
		<div class="list-products">
			<div class="main-products-container" v-if="receiveProducts.elements.thirdGroup !== undefined && receiveProducts.elements.thirdGroup.length !== 0">
				<div class="main-container">
					<div class="card-products" v-for="(elem, index) in receiveProducts.elements.thirdGroup" :key="elem.id" :class="{ 'big-card': index === 0, 'small-card': index === 1 }">
						<img :src="elem.images[0] && elem.images[0].minimizedImages['image500'] ? elem.images[0].minimizedImages['image500'] .url : ''" />
						<div class="wrapper-desc">
							<h5 class="product-name" @click="transitionToSelectProduct(elem.id)">{{ elem.title }}</h5>
							<h5 class="product-price">€ {{ elem.price / 100 }} per rose</h5>
						</div>
					</div>
				</div>
			</div>
			<div class="secondary-products-container" v-if="receiveProducts.elements.firstGroup !== undefined && receiveProducts.elements.firstGroup.length !== 0">
				<div class="main-container">
					<div class="card-products small-card" v-for="elem in receiveProducts.elements.firstGroup" :key="elem.id">
						<div class="small-card-content">
							<img :src="elem.images[0] && elem.images[0].minimizedImages['image500']  ? elem.images[0].minimizedImages['image500'] .url : ''" />
							<div class="wrapper-desc">
								<h5 class="product-name" @click="transitionToSelectProduct(elem.id)">{{ elem.title }}</h5>
								<h5 class="product-price">from € {{ elem.price / 100 }}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="main-products-container reverse" v-if="receiveProducts.elements.fourthGroup !== undefined && receiveProducts.elements.fourthGroup.length !== 0">
				<div class="main-container">
					<div class="card-products" v-for="(elem, index) in receiveProducts.elements.fourthGroup" :key="elem.id" :class="{ 'big-card': index === 0, 'small-card': index === 1 }">
						<img :src="elem.images[0] && elem.images[0].minimizedImages['image500']  ? elem.images[0].minimizedImages['image500'] .url : ''" />
						<div class="wrapper-desc">
							<h5 class="product-name" @click="transitionToSelectProduct(elem.id)">{{ elem.title }}</h5>
							<h5 class="product-price">€ {{ elem.price / 100 }} per rose</h5>
						</div>
					</div>
				</div>
			</div>
			<div class="secondary-products-container" v-if="receiveProducts.elements.secondGroup !== undefined && receiveProducts.elements.secondGroup.length !== 0">
				<div class="main-container">
					<div class="card-products small-card" v-for="elem in receiveProducts.elements.secondGroup" :key="elem.id">
						<div class="small-card-content">
							<img :src="elem.images[0] && elem.images[0].minimizedImages['image500']  ? elem.images[0].minimizedImages['image500'] .url : ''" />
							<div class="wrapper-desc">
								<h5 class="product-name" @click="transitionToSelectProduct(elem.id)">{{ elem.title }}</h5>
								<h5 class="product-price">from € {{ elem.price / 100 }}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="last-product-container" v-if="receiveProducts.elements.fifthGroup !== undefined && receiveProducts.elements.fifthGroup.length !== 0">
				<div class="main-container">
					<div class="card-products big-card" v-for="elem in receiveProducts.elements.fifthGroup" :key="elem.id">
						<img :src="elem.images[0] && elem.images[0].minimizedImages['image500']  ? elem.images[0].minimizedImages['image500'] .url : ''" />
						<div class="wrapper-desc">
							<h5 class="product-name" @click="transitionToSelectProduct(elem.id)">{{ elem.title }}</h5>
							<h5 class="product-price">from € {{ elem.price / 100 }}</h5>
						</div>
					</div>
				</div>
			</div>
			<div class="product-pagination">
				<div class="quantity-elements">Showing 11 products from 150</div>
				<div v-if="receiveProducts.totalPages">
					<sliding-pagination :current="currentPage" :total="receiveProducts.totalPages" @page-change="pageChangeHandler"></sliding-pagination>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import "vue-range-component/dist/vue-range-slider.css";
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
import Slider from "@vueform/slider/dist/slider.vue2";
import _ from "lodash";

export default {
	name: "Shop",
	data() {
		return {
			selectCategory: "All products",
			searchActive: false,
			filtrationActive: false,
			rangeValue: [8.99, 100],
			priceActive: false,
			categoryActive: false,
			categoryList: [],
			currentPage: 1,
			categoryProduct: {
				id: null,
				page: null
			},
			filterProduct: {
				page: 0,
				price: [0, 100],
				category: null
			},
			stateCategoryTop: false,
			stateCategoryFilter: false,
			quantityCategoryProducts: 0,
			option: {
				tooltipPosition: "bottom",
				format: {
					prefix: "€ ",
					decimals: 2
				}
			},
			search: {
				inputData: "",
				page: 0
			}
		};
	},
	components: {
		Slider,
		SlidingPagination: () => import("vue-sliding-pagination")
	},
	watch: {
		categoryList() {
			this.filterProduct.category = this.categoryList;
		},
		rangeValue() {
			this.filterProduct.price = this.rangeValue;
		},
		"search.inputData"() {
			this.debouncedGetAnswer();
		}
	},
	methods: {
		setSelectCategory(event, id) {
			let vm = this;
			vm.$loading(true);
			this.categoryProduct.id = id;
			this.categoryProduct.page = 0;
			this.currentPage = 1;
			this.stateCategoryTop = true;
			this.stateCategoryFilter = false;
			if (id !== null) {
				this.$store.dispatch("getCategoryProduct", this.categoryProduct);
			} else {
				this.$store.dispatch("getProducts");
				this.stateCategoryFilter = false;
				this.stateCategoryTop = false;
			}
			const categoryItems = document.querySelectorAll(".categories-list__item");
			categoryItems.forEach((el) => {
				el.classList.remove("select");
				if (el === event) {
					el.classList.add("select");
					this.selectCategory = event.textContent;
				}
			});
		},
		setSearchActive() {
			this.searchActive = !this.searchActive;
		},
		setFiltrationActive() {
			this.filtrationActive = !this.filtrationActive;
			this.priceActive = false;
			this.categoryActive = false;
		},
		setPriceActive() {
			this.priceActive = !this.priceActive;
			this.categoryActive = false;
		},
		setCategoryActive() {
			this.categoryActive = !this.categoryActive;
			this.priceActive = false;
		},
		pageChangeHandler(selectedPage) {
			let vm = this;
			console.log(vm.$loading(true));
			this.currentPage = selectedPage;
			this.categoryProduct.page = selectedPage - 1;
			this.filterProduct.page = selectedPage - 1;
			if (this.stateCategoryTop) {
				this.$store.dispatch("getCategoryProduct", this.categoryProduct);
			} else if (this.stateCategoryFilter) {
				this.$store.dispatch("getFilterProduct", this.filterProduct);
			} else {
				this.$store.dispatch("getProducts", selectedPage - 1);
			}
		},
		setQuantityProductsInCategory(categoryProductQuantity, id) {
			this.categoryList.includes(id) ? (this.quantityCategoryProducts += categoryProductQuantity) : (this.quantityCategoryProducts -= categoryProductQuantity);
		},
		filterRequest() {
			this.stateCategoryTop = false;
			this.stateCategoryFilter = true;
			this.$store.dispatch("getFilterProduct", this.filterProduct);
			this.categoryActive = false;
			this.priceActive = false;
		},
		clearAll() {
			this.categoryList = [];
			this.rangeValue = [this.receivePrice.min, this.receivePrice.max];
			this.$store.dispatch("getProducts", 0);
		},
		searchRequest() {
			if (this.search.inputData.length !== 0) {
				this.$store.dispatch("getSearchProduct", this.search);
			} else {
				this.$store.dispatch("getProducts", 0);
			}
		},
		transitionToSelectProduct(id) {
			this.$store.dispatch("getSelectProduct", id);
			this.$router.push({ name: "selectProduct", params: { productId: id } });
		}
	},
	computed: {
		receiveProducts() {
			const products = this.$store.getters["receiveProduct"].product;
			if (products) {
				let vm = this;
				console.log(vm.$loading(false));
			}
			const totalPages = parseInt(this.$store.getters["receiveProduct"].pages);
			const elements = {
				firstGroup: products?.splice(0, 3),
				secondGroup: products?.splice(0, 3),
				thirdGroup: products?.splice(0, 2),
				fourthGroup: products?.splice(0, 2),
				fifthGroup: products
			};
			return { elements, totalPages };
		},
		receiveCategory() {
			return this.$store.getters["receiveCategory"];
		},
		receivePrice() {
			return this.$store.getters["receivePrice"] === null ? { min: 0, max: 50 } : this.$store.getters["receivePrice"];
		}
	},

	created() {
		this.$store.dispatch("getProducts");
		this.$store.dispatch("getCategory");
		this.$store.dispatch("getAllPrice");
		let vm = this;
		console.log(vm.$loading(true));
		this.debouncedGetAnswer = _.debounce(this.searchRequest, 1500); //lodash library
	}
};
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style>
.slider-purple {
	--slider-connect-bg: #8183fc;
	--slider-bg: #3d3c41;
	--slider-tooltip-bg: none;
	--slider-handle-ring-color: none;
	--slider-handle-bg: #682df5;
	--slider-tooltip-font-size: 14px;
}
.slider-horizontal .slider-tooltip-bottom:before {
	display: none;
}
</style>
<style scoped lang="scss">
@import "Shop";
</style>
